export const apiBaseUrl = 'https://streamlux-api.azurewebsites.net';
// export const apiBaseUrl = 'http://localhost:7071';

const PlanIds = {
    free: undefined,
    testPro: "P-9R026375TH8352458MBGBDAA",
    testBasic: "P-7P8098247P972952EMBET4FA",
};

export const AppWriteVerifyUrl = 'https://streamlux.com/confirm';

export const appwriteRecoveryUrl = 'https://streamlux.com/recover';

export const discordLink = 'https://discord.gg/a6RZrjdaCt';

export const live = false;
